
import featureState from './icons/state.svg';

const PriceFeatures = ({ features }) => {
	return <div className='priceFeatures'>
		{features?.map((feature, index) => {
			const { isEnable, label } = feature;

			return <div key={index} className='priceFeature'>
				<svg className={isEnable ? 'priceFeatureEnabled' : 'priceFeatureDisabled'}>
					<use xlinkHref={`${featureState}#${isEnable ? 'enabled' : 'disabled'}`}></use>
				</svg>

				<span className='priceFeatureLabel' dangerouslySetInnerHTML={{ __html: label }} />
			</div>;
		})}
	</div>
}
export default PriceFeatures;